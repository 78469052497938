<template>
    <validation-provider
            v-slot="{ errors }"
            :name="$attrs.name"
            :rules="$props.validation"
    >
        <v-autocomplete v-bind="$attrs"
                        v-on="$listeners"
                        outlined
                        dense
                        hide-details
                        :error-messages="errors"
        >
        </v-autocomplete>
    </validation-provider>
</template>

<script>
    export default {
        name: 'ZwAutocomplete',
        props: {
            validation: {
                type: [String, Object],
            }
        },
    }
</script>